// Generated by Framer (be6943a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I5gLC4ueO", "it58sTT43", "yoi6ZJFlL", "CS7948pNQ", "X5apd6JSR", "EPn07DccX", "hxzWU6msZ", "Aa2OtyHog", "yjMyabAf1"];

const serializationHash = "framer-hNZU0"

const variantClassNames = {Aa2OtyHog: "framer-v-efija8", CS7948pNQ: "framer-v-1kgcm3c", EPn07DccX: "framer-v-h69he7", hxzWU6msZ: "framer-v-7aiybs", I5gLC4ueO: "framer-v-j6x6e2", it58sTT43: "framer-v-phex74", X5apd6JSR: "framer-v-6h3itv", yjMyabAf1: "framer-v-yzq1tg", yoi6ZJFlL: "framer-v-3i3sw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "EPn07DccX", "Image 16:9": "CS7948pNQ", "Image 3:2": "yoi6ZJFlL", "Image 4:3": "it58sTT43", "Image 4:5": "X5apd6JSR", "Variant 7": "hxzWU6msZ", "Variant 8": "Aa2OtyHog", "Variant 9": "yjMyabAf1", Default: "I5gLC4ueO"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I5gLC4ueO", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "I5gLC4ueO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({CS7948pNQ: {value: transition2}}, baseVariant, gestureVariant)}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-j6x6e2", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"I5gLC4ueO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Aa2OtyHog: {"data-framer-name": "Variant 8"}, CS7948pNQ: {"data-framer-name": "Image 16:9"}, EPn07DccX: {"data-framer-name": "Image 1:1"}, hxzWU6msZ: {"data-framer-name": "Variant 7"}, it58sTT43: {"data-framer-name": "Image 4:3"}, X5apd6JSR: {"data-framer-name": "Image 4:5"}, yjMyabAf1: {"data-framer-name": "Variant 9"}, yoi6ZJFlL: {"data-framer-name": "Image 3:2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hNZU0.framer-m9335a, .framer-hNZU0 .framer-m9335a { display: block; }", ".framer-hNZU0.framer-j6x6e2 { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-hNZU0.framer-v-phex74.framer-j6x6e2 { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-hNZU0.framer-v-3i3sw.framer-j6x6e2 { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-hNZU0.framer-v-1kgcm3c.framer-j6x6e2 { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-hNZU0.framer-v-6h3itv.framer-j6x6e2 { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-hNZU0.framer-v-h69he7.framer-j6x6e2 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-hNZU0.framer-v-7aiybs.framer-j6x6e2 { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-hNZU0.framer-v-efija8.framer-j6x6e2 { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-hNZU0.framer-v-yzq1tg.framer-j6x6e2 { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"it58sTT43":{"layout":["fixed","fixed"]},"yoi6ZJFlL":{"layout":["fixed","fixed"]},"CS7948pNQ":{"layout":["fixed","fixed"]},"X5apd6JSR":{"layout":["fixed","fixed"]},"EPn07DccX":{"layout":["fixed","fixed"]},"hxzWU6msZ":{"layout":["fixed","fixed"]},"Aa2OtyHog":{"layout":["fixed","fixed"]},"yjMyabAf1":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermTeoXMnww: React.ComponentType<Props> = withCSS(Component, css, "framer-hNZU0") as typeof Component;
export default FramermTeoXMnww;

FramermTeoXMnww.displayName = "Image / Aspect Ratio Copy 11";

FramermTeoXMnww.defaultProps = {height: 256, width: 256};

addPropertyControls(FramermTeoXMnww, {variant: {options: ["I5gLC4ueO", "it58sTT43", "yoi6ZJFlL", "CS7948pNQ", "X5apd6JSR", "EPn07DccX", "hxzWU6msZ", "Aa2OtyHog", "yjMyabAf1"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramermTeoXMnww, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})